$(document).on('turbolinks:load', function() {
  number_of_cart_items();
  set_finish_option();
  flatten_one_side_visibility();
  calculate_price();
  $('.item-quantity').on('change', function() {
    calculate_price();
  });
});

function set_finish_option() {
  finish_row_visibility();
  $('.finishing-option').on('change', function() {
    finish_row_visibility();
    flatten_one_side_visibility();
    sub_options_init();
    calculate_price();
  });
}

function sub_options_init() {
  $("[data-sub-options]").hide();
  var checked = $('.finishing-option:checked').val();
  $("[data-sub-options='" + checked + "']").show();
}

function flatten_one_side_visibility() {
  if ($('.finishing-option:checked').data("side-chooser")){
    $('.flatten-one-side-only').show();
    $('.finishing-option-side').prop('required', true);
  }
  else {
    $('.flatten-one-side-only').hide();
    $('.finishing-option-side').prop('required', false);
  }
}

function finish_row_visibility(){
  if ($('.finishing-option:checked').val() === 'No Work')
    $('.finish-row').hide();
  else
    $('.finish-row').show();
  $('#finishing-option-price').val($('.finishing-option:checked').attr('id'));
}

function calculate_price() {
  total = $('.item-quantity').val() * (($('.item-price').val() * 1) + (( $('#finishing-option-price').val() || 0) * 1))
  $('.order-box-total').text('$' + formatNumber(total));
}

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function number_of_cart_items(){
  if ($('.line-item-size').val() > 0) {
    $('.cart-items')
      .text($('.line-item-size').val())
      .addClass("has-items");
  }
}
