$(document).on('turbolinks:load', function() {
  if($("#card-number").length) {
    initStripe();
  }
});

// Submit the form with the token ID.
function stripeTokenHandler(token) {
  // Insert the token ID into the form so it gets submitted to the server
  var form = document.getElementById('payment-form');
  var hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'stripeToken');
  hiddenInput.setAttribute('value', token.id);
  form.appendChild(hiddenInput);

  // Submit the form
  form.submit();
}

function initStripe() {
  // Create a Stripe client.
  stripe_public_key = $("meta[name='stripe-key']").attr("content")
  var stripe = Stripe(stripe_public_key)

  // Create an instance of Elements.
  var elements = stripe.elements();

  // Custom styling can be passed to options when creating an Element.
  // (Note that this demo uses a wider set of styles than the guide below.)
  var style = {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  // Create an instance of the card Element.
  var card = elements.create('cardNumber', {
    classes: {
    base: 'form-control'
  },
    style: style,
    showIcon: true
  });
  var expiry = elements.create('cardExpiry', {
    classes: {
      base: 'form-control'
    },
    style: style
  });
  var cvc = elements.create('cardCvc', {
    classes: {
      base: 'form-control'
    },
    style: style
  });

  // Add an instance of the card Element into the `card-element` <div>.
  card.mount('#card-number');
  expiry.mount('#card-expiry');
  cvc.mount('#card-cvc');

  // Handle real-time validation errors from the card Element.
  card.on('change', function (event) {
    var displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
      $('.submit-button').removeAttr("disabled");
    } else {
      displayError.textContent = '';
    }
  });

// Handle form submission.
  var form = document.getElementById('payment-form');
  form.addEventListener('submit', function (event) {
    if ($('.order').attr('id') == 'payment-form')
      event.preventDefault();

    stripe.createToken(card).then(function (result) {
      if (result.error) {
        // Inform the user if there was an error.
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
        $('.submit-button').removeAttr("disabled");
      } else {
        // Send the token to your server.
        stripeTokenHandler(result.token);
      }
    });
  });

}
