// You should import the CSS file.
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';

$(document).on('turbolinks:load', function() {
  const el = document.getElementById('product-images')
  if(el) {
    const gallery = new Viewer(el, {
      url(image) {
        return image.dataset.largesrc
      }
    });

  }
})
// View a list of images
// Then, show one image by click it, or call `gallery.show()`.
