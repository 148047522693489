$(document).on('turbolinks:load', function() {
  submit_button_visiblilty();
  $('.payment-method').on('change', function() {
    submit_button_visiblilty();
  });
});

function submit_button_visiblilty() {
  $('.cash-submit-button').hide();
  $('.card-payment').hide();
  if ($('.payment-method:checked').val() == 'cash_on_pickup') {
    $('.order').attr('id', 'pickup-form');
    $('.cash-submit-button').show();
  }
  else if ($('.payment-method:checked').val() == 'card') {
    $('.card-payment').show();
    $('.order').attr('id', 'payment-form');
  }
  else {
    $('.cash-submit-button').hide();
    $('.card-payment').hide();
  }
}
